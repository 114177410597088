import getUrlAndLocation from "./getUrlAndLocation";
import goToVueInRoot from "./goToVueInRoot";

export const AppStoreToDoorLanding = () => {
    const { _location, url } = getUrlAndLocation;

    goToVueInRoot(url, _location);

    return <></>;
};
