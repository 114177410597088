import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { AddressSelect } from "./AddressSelect";
import { HF } from "../../../../components";
import { ToggleBtn } from "../../../../components/ToggleBtn";
import {
  BaseSearchInputV2,
  SearchType,
} from "../../../../components/BaseSearchInput/BaseSearchInputV2";
import { CountrySelectV2 } from "../../../../components/CountrySelectV2";
import { StateSelect } from "../../../addressBookEditor/stateSelect/StateSelect";
import styles from "./ContactForm.scss";
import { BaseIcon, Name } from "../../../../components/BaseIcon";
import { onlyLetters } from "../../../../components/BaseInput/BaseInput";
import { selectIsDomesticShipping } from "../../../../data/selectors";
import { CompanyEinTooltip } from "../../../addressBookEditor/CompanyEinTooltip";
import { PassportTooltip } from "../../../addressBookEditor/PassportTooltip";
import { SearchAddressPassportTooltip } from "../../../addressBookEditor/SearchAddressPassportTooltip";
import { countriesWithStates } from "../../../../constants";

interface ContactFormProps {
  formValues: any;
  formName: any;
  methods: any;
  isCompanyChecked: boolean;
  isAddressFromType?: boolean;
  withEmail?: boolean;
  countryLocked?: boolean;
  formIsActive: boolean;
  change: (fieldName: string, payload: any) => void;
  onSelect?: (fieldName: string) => void;
  onSelectAddressBook(address: string): void;
}

export const ContactForm = ({
  formValues,
  formName,
  methods,
  isCompanyChecked,
  isAddressFromType,
  withEmail,
  formIsActive,
  countryLocked = false,
  change,
  onSelectAddressBook,
}: ContactFormProps) => {
  const [isShowsAddressFields, setIsShowsAddressFields] = useState<boolean>(
    !!formValues[formName].street_1,
  );
  const [isCountryHasState, setIsCountryHasState] = useState<boolean>(
    countriesWithStates.includes(formValues[formName]?.country_code),
  );
  // const [selectedAddressBook, setSelectedAddressBook] = useState<string>()

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  useEffect(() => {
    // console.log("=useEffect methods.formState.isSubmitted=", methods.formState.isSubmitted);
    methods.formState.isSubmitted && setIsShowsAddressFields(true);
  }, [methods.formState.isSubmitted]);

  useEffect(() => {
    // console.log("=useEffect setIsCountryHasState=", methods.getValues(`${formName}.country_code`), countriesWithStates.includes(methods.getValues(`${formName}.country_code`)));
    setIsCountryHasState(
      countriesWithStates.includes(
        methods.getValues(`${formName}.country_code`),
      ),
    );
    methods.setValue(
      `${formName}.isCountryHasState`,
      countriesWithStates.includes(
        methods.getValues(`${formName}.country_code`),
      ),
    );
  }, [methods.watch(`${formName}.country_code`)]);

  const handleOnKeyPress = (key: any, value: any): boolean => {
    const spaceCount = `${value}${key}`.match(/\s/g)?.length || 0;
    const hyphenCount = `${value}${key}`.match(/-/g)?.length || 0;

    if (spaceCount > 1) return false;
    if (hyphenCount > 1) return false;
    if (spaceCount && hyphenCount) return false;

    return /[A-Z]-?(\s)?[A-Z]?$/gi.test(`${value}${key}`);
  };

  const handleBlur = (fieldName: string, value: string): void => {
    if (value[value.length - 1] === "-") {
      change(fieldName, value.slice(0, value.length - 1));
    } else {
      change(fieldName, value.trim());
    }
  };

  const handleOnKeyPress2 = (key: any, value: any): boolean => {
    // console.log("=handleOnKeyPress2=", key, value);

    function extractSpecialCharacters(text: string) {
      const specialChars = text.split("").filter((char: string) => {
        if (isDomesticShipping) {
            //Allow Hebrew characters for domestic shipping
            return !char.match(/[0-9a-zA-Z\s,'"#@\-+.!()_&\][`\u0590-\u05FF]/);
        }
        return !char.match(/[0-9a-zA-Z\s,'"#@\-+.!()_&\][`]/);
      });

      return specialChars;
    }

    methods.trigger(`${formName}.postal_code`);

    // console.log("=extractSpecialCharacters=", extractSpecialCharacters(key));

    return extractSpecialCharacters(key).length === 0;
  };

  // console.log("=formValues=", formValues[formName]);

  return (
    <div>
      <ToggleBtn
        className={styles.privateCompanyToggle}
        selected={formValues[formName]["is_company"] || false}
        options={[
          {
            label: "Private",
            value: false,
          },
          {
            label: "Company",
            value: true,
          },
        ]}
        onChange={(value: boolean) => change(`${[formName]}.is_company`, value)}
      />

      <Grid item xs={12} md={6} style={{ marginBottom: "20px" }}>
        <AddressSelect
          // value={selectedAddressBook}
          countryCode={
            (formName !== "billing_address" &&
              formValues[formName]?.country_code) ||
            ""
          }
          refreshList={formIsActive}
          onSelected={(address: any) => {
            // console.log("=onSelected=", address);
            methods.clearErrors();
            onSelectAddressBook(address);
            // setSelectedAddressBook(address)
            setIsShowsAddressFields(true);
          }}
        />
      </Grid>

      <Grid container columnSpacing={{ xs: 2 }}>
        <Grid item xs={12} md={6}>
          <HF.Input
            name={`${[formName]}.first_name`}
            value={formValues[formName]?.first_name || ""}
            label="First Name"
            onKeyPress={handleOnKeyPress}
            onBlur={(e: any): void =>
              handleBlur(`${[formName]}.first_name`, e.target.value)
            }
            normalize={onlyLetters}
            onlyLetters
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HF.Input
            name={`${[formName]}.last_name`}
            value={formValues[formName]?.last_name || ""}
            label="Last Name"
            onKeyPress={handleOnKeyPress}
            onBlur={(e: any): void =>
              handleBlur(`${[formName]}.last_name`, e.target.value)
            }
            normalize={onlyLetters}
            onlyLetters
          />
        </Grid>
        <Grid className={styles.input} item xs={12} md={6}>
          <HF.PhoneInput
            name={`${[formName]}.phone`}
            value={formValues[formName]?.phone || ""}
            // defaultCountry={isDomesticShipping ? formValues[formName]?.country_code : 'US'}
            defaultCountry={formValues[formName]?.country_code || "US"}
          />
        </Grid>

        {withEmail && (
          <Grid item xs={12} md={6}>
            <HF.Input
              name={`${[formName]}.email`}
              value={formValues[formName]?.email || ""}
              label="E-mail"
              type="email"
            />
          </Grid>
        )}

        {isCompanyChecked ? (
          <>
            <Grid item xs={12} md={6}>
              <HF.Input
                name={`${[formName]}.company_name`}
                value={formValues[formName]?.company_name || ""}
                onKeyPress={handleOnKeyPress2}
                label="Company name"
              />
            </Grid>

            <Grid
              style={{ display: "flex", alignItems: "center" }}
              item
              xs={12}
              md={6}
            >
              <HF.Input
                name={`${[formName]}.company_ein`}
                value={formValues[formName]?.company_ein || ""}
                onKeyPress={handleOnKeyPress2}
                label="Tax ID / VAT number"
              />

              <CompanyEinTooltip />
            </Grid>
          </>
        ) : (
          <Grid
            style={{ display: "flex", alignItems: "center" }}
            xs={12}
            md={6}
            item
          >
            <HF.Input
              name={`${[formName]}.passport`}
              value={formValues[formName]?.passport || ""}
              onKeyPress={handleOnKeyPress2}
              label="Personal ID"
            />

            <PassportTooltip />
          </Grid>
        )}
      </Grid>

      <h6 className={styles.ContactFormSectionTitle}>Address details</h6>

      <div className={styles.SearchAddress}>
        <BaseSearchInputV2
          className={styles.input}
          value={formValues[formName]?.fullAddress || ""}
          addressDetails={formValues[formName]}
          searchType={SearchType.address}
          useCountry={countryLocked}
          popupIcon={() => <></>}
          label={`Search for an address${countryLocked && methods.watch(`${formName}.country`) ? ` in ${methods.getValues(`${formName}.country`)}` : ""}`}
          noOptionsText="No addresses"
          hideAutocomplete
          disableClearable
          blurOnSelect
          onInputChange={(payload: any) => {
            // console.log("=onInputChange=", payload);
            change(`${[formName]}.fullAddress`, payload);
          }}
          onChange={(payload: any) => {
            // console.log("=onChange=", payload);

            Object.keys(payload.address).forEach((keyName) => {
              change(`${[formName]}.${keyName}`, payload.address[keyName]);
            });
            change(`${[formName]}.fullAddress`, "");

            methods.clearErrors(`${[formName]}.country_code`);
            methods.clearErrors(`${[formName]}.city_name`);
            methods.clearErrors(`${[formName]}.street_1`);
            methods.clearErrors(`${[formName]}.street_2`);
            methods.clearErrors(`${[formName]}.state`);
            methods.clearErrors(`${[formName]}.postal_code`);

            methods.getValues(`${[formName]}.postal_code`) ||
              methods.trigger(`${[formName]}.postal_code`);

            setIsShowsAddressFields(true);
          }}
        />

        <SearchAddressPassportTooltip />
      </div>

      {isShowsAddressFields ? (
        <>
          <Grid container columnSpacing={{ xs: 2 }}>
            <Grid item xs={12} md={6}>
              <CountrySelectV2
                style={{ marginBottom: "20px" }}
                name={`${formName}.country_code`}
                countryCode={formValues[formName]?.country_code || ""}
                label="Country"
                disabled={countryLocked}
                disableClearable
                onChange={(value) => {
                  // console.log("=onChange=", value);

                  change(`${[formName]}.country_code`, value?.value ?? null);
                  change(`${[formName]}.country`, value?.label ?? null);

                  !value && change(`${[formName]}.state`, null);
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <HF.Input
                name={`${[formName]}.city_name`}
                value={formValues[formName]?.city_name || ""}
                onKeyPress={handleOnKeyPress2}
                label="City"
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ xs: 2 }}>
            <Grid item xs={12} md={6}>
              <HF.Input
                name={`${[formName]}.street_1`}
                value={formValues[formName]?.street_1 || ""}
                onKeyPress={handleOnKeyPress2}
                label="Address"
                minlength="3"
                maxlength="35"
                pattern=".{3,35}"
                title="3 to 35 characters"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <HF.Input
                name={`${[formName]}.street_2`}
                value={formValues[formName]?.street_2 || ""}
                onKeyPress={handleOnKeyPress2}
                label="Entrance, floor, apartment, etc."
                maxlength="35"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={{ xs: 2 }}>
            {/* <Grid item xs={12} md={6}>
            </Grid> */}

            {/* ={JSON.stringify(formValues[formName].state)}= */}
            {isCountryHasState ? (
              <Grid item xs={12} md={6}>
                <StateSelect
                  style={{ marginBottom: "20px" }}
                  name={`${formName}.state`}
                  countryCode={formValues[formName]?.country_code}
                  value={formValues[formName]?.state || ""}
                  register={methods.register(`${formName}.state`)}
                  disableClearable
                  onChange={(state) => {
                    // console.log("=onChange=", state);
                    change(`${formName}.state`, state);

                    methods.trigger(`${formName}.state`);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6}></Grid>
            )}

            <Grid item xs={12} md={6}>
              <HF.Input
                name={`${[formName]}.postal_code`}
                value={formValues[formName]?.postal_code || ""}
                onKeyPress={handleOnKeyPress2}
                label="Zip / Postal Code"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <button
          className={styles.addAddressManually}
          onClick={() => setIsShowsAddressFields(true)}
        >
          <BaseIcon
            className={styles.addAddressManuallyIcon}
            name={Name.Plus}
            size={12}
          />
          Add address manually
        </button>
      )}

      {isAddressFromType && (
        <>
          {/* <StyledLabel>Instructions to the courier:</StyledLabel> */}

          <HF.Input
            className={styles.textarea}
            label="Helpful pick up instructions to courier"
            name={`${[formName]}.instructions`}
            type="textarea"
            multiline
          />
        </>
      )}
    </div>
  );
};
