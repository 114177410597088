import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";

import { history } from "./services/history";
import { theme } from "./css/theme";
import { Error404 } from "./scenes/error404";
import { TERMS_OF_USE_SECTIONS } from "./constants/TermsOfUse";
import { getUser } from "./data/actions";
import { PRIVACY_POLICY_SECTIONS } from "./constants/PrivacyPolicySections";
import { refundPolicyContentPage } from "./constants/RefundPolicyContentPage";

import { HeaderAndFooter as HeaderAndFooterLayout } from "./layouts/HeaderAndFooter";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ScrollToTop } from "./components/ScrollToTop";

import { setRtl, setLocale } from "./data/actions";
import { languageOptions } from "./components/localization/Localization";

import { Home } from "./scenes/home";
import HomeRtl from "./scenes/homeRtl/HomeRtl";
import HomeTr from "./scenes/homeTr/HomeTr";
import { selectIsAuth } from "./scenes/auth/data/selectors";
import { Auth } from "./scenes/auth";
import { AuthSocial } from "./scenes/auth/scenes/authSocial/AuthSocial";
import { AuthError } from "./scenes/auth/scenes/authError/AuthError";
import { Prices } from "./scenes/home/scenes/prices";
import { Checkout } from "./scenes/checkout/Checkout";
import { PaymentResult } from "./scenes/payment/scenes/success";
import { TextPage } from "./scenes/textPage";
import { Contacts } from "./scenes/contacts";
import { AppProfile } from "./scenes/profile/Profile";
import { AppAddressBook } from "./scenes/addressBook/AppAddressBook";
import { AppAddressBookEditor } from "./scenes/addressBookEditor/AppAddressBookEditor";
import { Verify } from "./scenes/profile/scenes/verify";
import { Logout } from "./integrationWithVue/Logout";
import { AppSuccessPaymentMiddleware } from "./scenes/paymentMiddleware/AppSuccessPaymentMiddleware";
import { AppErrorPaymentMiddleware } from "./scenes/paymentMiddleware/AppErrorPaymentMiddleware";

import { getUiBreakpoint } from "./services/breakpoint/getUIBreakpoint";
import { ModalUpdateMobileApp } from "./ModalUpdateMobileApp";
// import{ mobileVendor } from 'react-device-detect';

import { getStore, removeStore } from "./integrationWithVue/handleBrowserStore";
import { checkIsVueRoute } from "./integrationWithVue/checkIsVueRoute";
import getUrlAndLocation from "./integrationWithVue/getUrlAndLocation";
import goToVueInRoot from "./integrationWithVue/goToVueInRoot";
import { AppPasswordReset } from "./integrationWithVue/AppPasswordReset";
import { AppTracking } from "./integrationWithVue/AppTracking";
import { AppPayment } from "./integrationWithVue/AppPayment";
import { AppBlog } from "./integrationWithVue/AppBlog";
import { AppBilling } from "./integrationWithVue/AppBilling";
import { AppAboutUs } from "./integrationWithVue/AppAboutUs";
// import { setPersonInfobip } from "./plugins/infobip/infobip";

import "./css/index.scss";
import {AppStoreToDoorLanding} from "./integrationWithVue/AppStoreToDoorLanding";
(window as any).getUiBreakpoint = getUiBreakpoint;

const Root = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(true);
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    // console.log("=useEffect=");

    if (getStore("token")) {
      dispatch(getUser());
    } else {
      setLoading(false);
      // process.env.REACT_APP_INFOBIP && setPersonInfobip();
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      setLoading(false);
    }
  }, [isAuth]);

  // === Это для интеграции со VUE START ===
  // console.log("=Root=", getStore("locale-re"));
  // console.log("= window.history=", document.referrer, window.history.state, window.location);
  //  console.log("=history.location=", history.location);
  //  console.log("=globalThis.location=", globalThis.location);

  useEffect(() => {
    // (async () => {
    // ===== Попытка сделать переадресацию с сайта в приложение START =====
    // if (process.env.REACT_APP_IS_MOBILE_APP !== 'true') {
    //   if (mobileVendor === 'Apple') {
    //     console.log("=IS mobileVendor === 'Apple'=", );
    //     try {
    //       document.location = `shipperglobalapp:/${history.location.pathname}${history.location.search}`;
    //       console.error("=HAVE URL_SCHEME=");
    //    } catch (err) {
    //     console.error("=NO URL_SCHEME=", err);
    //    }
    //     window.location.href = `shipperglobalapp:/${history.location.pathname}${history.location.search}`
    //   }
    // }
    // ===== END =====

    //  console.log("=useEffect ROOT url=", url, process.env.REACT_APP_IS_MOBILE_APP === 'true');

    const { _location, url } = getUrlAndLocation;
    // console.log("= _location=", _location, url, getStore("locale-re"));

    if (checkIsVueRoute(_location.pathname)) {
      goToVueInRoot(url, _location);
    } else {
      //  console.log("= ELSE checkIsVueRoute =", _location.pathname === '/index.html' || _location.pathname === '/', _location.pathname );
      // TODO: добавлено экспериментально
      removeStore();

      if (
        (!url || url === "/") &&
        (_location.pathname === "/index.html" ||
          _location.pathname === "/" ||
          _location.pathname.startsWith("/he") ||
          _location.pathname.startsWith("/tr"))
      ) {
        const localeLocalStorage = getStore("locale-re") || "";
        const localeBrowser = navigator.language;
        // console.log(
        //   "=localeBrowser, localeLocalStorage=",
        //   localeBrowser,
        //   localeLocalStorage,
        //   process.env.REACT_APP_IS_MOBILE_APP === "true"
        // );
        let locale: string;

        if (_location.pathname.startsWith("/he")) {
          locale = "he";
        } else if (_location.pathname.startsWith("/tr")) {
          locale = "tr";
        } else if (
          languageOptions.find((item: any) => item.value === localeLocalStorage)
        ) {
          locale = localeLocalStorage;
        } else if (
          languageOptions.find((item: any) => item.value === localeBrowser)
        ) {
          locale = localeBrowser;
        } else {
          locale = "en";
        }

        // console.log("=Root setLocale=", locale);
        dispatch(setRtl(locale === "he"));
        dispatch(setLocale(locale));

        if (locale !== "en") {
          if (!_location.pathname.startsWith(`/${locale}`)) {
            const _pathname = `/${locale}${url ?? _location.pathname}`.replace(
              "/index.html",
              ""
            );
            console.log("=_pathname=", _pathname);

            history.replace(_pathname);
          }
        }
      } else if (url) {
        history.replace(url ?? "/");
      }
    }
    // })()
  }, []);
  // === END ===

  return (
    <>
      {!loading && (
        <HistoryRouter history={history}>
          <ThemeProvider theme={theme}>
            <ScrollToTop>
              <Routes>
                <Route
                  path="/"
                  // element={<HeaderAndFooterLayout withoutFooterInMobile />}
                  element={<HeaderAndFooterLayout />}
                >
                  <Route path="/" element={<Home />} />
                  <Route path="/he" element={<HomeRtl />} />
                  <Route path="/tr" element={<HomeTr />} />
                </Route>

                <Route
                  path="/profile/verify"
                  element={
                    <ProtectedRoute>
                      <Verify />
                    </ProtectedRoute>
                  }
                />

                <Route path="/" element={<HeaderAndFooterLayout />}>
                  <Route
                    path="/shipments/:id/checkout"
                    element={
                      <ProtectedRoute>
                        <Checkout />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/shipments/:id" element={<Prices />} />
                  <Route path="/payment/success" element={<PaymentResult />} />
                  <Route
                    path="/payment/error"
                    // element={<PaymentResult isError />}
                    element={<PaymentResult />}
                  />

                  <Route path="/contacts" element={<Contacts />} />
                  <Route
                    path="/privacy-policy"
                    element={
                      <TextPage
                        sections={PRIVACY_POLICY_SECTIONS}
                        title="Privacy policy"
                      />
                    }
                  />
                  <Route
                    path="/terms-of-use"
                    element={
                      <TextPage
                        sections={TERMS_OF_USE_SECTIONS}
                        title="Terms of Use "
                      />
                    }
                  />
                  <Route
                    path="/refund-policy"
                    element={
                      <TextPage
                        sections={refundPolicyContentPage}
                        title="Shipper Global - Refund Policy"
                      />
                    }
                  />
                  <Route path="*" element={<Error404 />} />
                </Route>

                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <AppProfile />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/address-book"
                  element={
                    <ProtectedRoute>
                      <AppAddressBook />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/address-book/create"
                  element={
                    <ProtectedRoute>
                      <AppAddressBookEditor />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/address-book/edit/:id"
                  element={
                    <ProtectedRoute>
                      <AppAddressBookEditor isEdit />
                    </ProtectedRoute>
                  }
                />

                <Route path="/auth" element={<Auth />} />
                <Route path="/socialite/:provider" element={<AuthSocial />} />
                <Route path="/auth-error" element={<AuthError />} />
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/payment/middleware/success"
                  element={<AppSuccessPaymentMiddleware />}
                />
                <Route
                  path="/payment/middleware/error"
                  element={<AppErrorPaymentMiddleware />}
                />
                <Route path="/password/reset" element={<AppPasswordReset />} />
                <Route path="/tracking" element={<AppTracking />} />
                <Route path="/payments/:paymentId" element={<AppPayment />} />
                <Route path="/blog" element={<AppBlog />} />
                <Route path="/billing/:tabName" element={<AppBilling />} />
                {/* FIXME: видимо нужно продублировать путь/роутер к Блогу с префиксом лакали/языка */}
                <Route path="/about-us" element={<AppAboutUs />} />
                <Route path="/store-to-door" element={<AppStoreToDoorLanding />} />
                <Route path="/store-to-door/shipments" element={<AppStoreToDoorLanding />} />
                <Route path="/store-to-door/onboarding" element={<AppStoreToDoorLanding />} />
                <Route path="/store-to-door/my-addresses" element={<AppStoreToDoorLanding />} />
              </Routes>
            </ScrollToTop>
          </ThemeProvider>

          {process.env.REACT_APP_IS_MOBILE_APP === "true" && (
            <ModalUpdateMobileApp />
          )}
        </HistoryRouter>
      )}
    </>
  );
};

export default Root;
