import * as React from "react";
import styled, { css } from "styled-components";

import * as Icons from "./components/Icons";

export enum Name {
  Close = "Close",
  BrandBox = "BrandBox",
  RedSquareInCircle = "RedSquareInCircle",
  WhiteRhombusInRedCircle = "WhiteRhombusInRedCircle",
  BlueSquareInCircle = "BlueSquareInCircle",
  EyeOpen = "EyeOpen",
  EyeClosed = "EyeClosed",
  Info = "Info",
  Search = "Search",
  LeftArrow = "LeftArrow",
  Pencil = "Pencil",
  DropdownArrow = "DropdownArrow",
  Envelope = "Envelope",
  Box = "Box",
  Instagram = "Instagram",
  Facebook = "Facebook",
  LinkedIn = "LinkedIn",
  PDF = "PDF",
  CloseInCircle = "CloseInCircle",
  Minus = "Minus",
  Plus = "Plus",
  Document = "Document",
  VerticalDots = "VerticalDots",
  Download = "Download",
  Copy = "Copy",
  LeftSmallArrow = "LeftSmallArrow",
  ThinClose = "ThinClose",
  Question = "Question",
  OutlinedBrandBox = "OutlinedBrandBox",
  Rocket = "Rocket",
  Phone = "Phone",
  UserInSquare = "UserInSquare",
  Location = "Location",
  Exit = "Exit",
  Check = "Check",
  CheckMarkElongated = "CheckMarkElongated",
  Trash = "Trash",
  QR = "QR",
  Swap = "Swap",
  USD = "USD",
  EUR = "EUR",
  ILS = "ILS",
  Accessibility = "Accessibility",
  Pallet = "Pallet",
  Blog = "Blog",
  Earth = "Earth",
  AboutUs = "AboutUs",
  Billing = "Billing",
  StoreToDoor = "StoreToDoor"
}

export enum Size {
  small = "sm",
  medium = "md",
  large = "lg",
}

interface IIconBase {
  color?: string;
  size?: Size | number;
  hoverColor?: string;
  bgColor?: string;
  hoverBgColor?: string;
  round?: boolean;
  isButton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export interface IProps extends IIconBase {
  name: Name;
  className?: string;
  testId?: string;
}

const sizeDictionary = {
  [Size.small]: 16,
  [Size.medium]: 24,
  [Size.large]: 48,
};

const sizeRoundDictionary: any = {
  16: 24,
  24: 40,
};

const getSize = (size?: Size | number): number => {
  if (typeof size === "number") {
    return size;
  }

  if (size && Object.keys(sizeDictionary).includes(size)) {
    return sizeDictionary[size];
  }

  return sizeDictionary[Size.medium];
};

/* stylelint-disable no-extra-semicolons */
const IconBase = styled.div<IIconBase>`
  display: inline-flex;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  ${
    /* sc-block */ ({ bgColor, color, size, isButton }) => {
      const sizeIcon = getSize(size);

      return css`
        width: ${sizeIcon}px;
        height: ${sizeIcon}px;
        color: ${color};
        fill: ${color};
        background-color: ${bgColor};
        border-radius: ${isButton ? "50%" : "none"};
        cursor: ${isButton ? "pointer" : "initial"};
      `;
    }
  };

  ${
    /* sc-block */ ({ round, size }) => {
      const sizeIcon = getSize(size);
      const sizeIconWrp: number =
        sizeRoundDictionary[sizeIcon] || sizeIcon + 10;

      return (
        round &&
        css`
          width: ${sizeIconWrp}px;
          height: ${sizeIconWrp}px;
          padding: ${(sizeIconWrp - sizeIcon) / 2}px;
          border-radius: 50%;
          box-sizing: border-box;
        `
      );
    }
  };

  :hover {
    color: ${({ hoverColor, size }) => hoverColor};
    background-color: ${({ isButton }) => (isButton ? "#E5E9F3" : "initial")};
    box-shadow: ${({ isButton }) =>
      isButton ? "0px 0px 0px 8px #E5E9F3" : "none"};
    transition: 0.2s ease-in-out;
  }
`;

const StyledBtn = styled.button`
  display: flex;
`;

const BaseIcon: React.FC<IProps> = (props) => {
  const { name, testId, onClick, ...rest } = props;

  const NamedIcon = Icons[name];
  if (!NamedIcon) {
    return null;
  }

  return (
    <>
      {props.isButton ? (
        <StyledBtn onClick={onClick} type="button" disabled={props.disabled}>
          <IconBase data-test-id={testId || `${name}IconButton`} {...rest}>
            <NamedIcon />
          </IconBase>
        </StyledBtn>
      ) : (
        <IconBase data-test-id={testId || `${name}Icon`} {...rest}>
          <NamedIcon />
        </IconBase>
      )}
    </>
  );
};

BaseIcon.defaultProps = {
  size: Size.medium,
  color: "currentColor",
  bgColor: "transparent",
};

export default BaseIcon;
