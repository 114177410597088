import { useState } from "react";
import { CardAddressBook } from "./CardAddressBook";
import { BasePagination } from "../../components/BasePagination";
import { EmptyListAddressBook } from "./EmptyListAddressBook";
import styles from "./ListAddressBook.scss";
import { useNavigate } from "react-router";

interface ListAddressBookProps {
  addressBook: any;
  onChangePage(page: any): void;
}

export const ListAddressBook = ({
  addressBook,
  onChangePage,
}: ListAddressBookProps) => {
  const [innserAddressBook, setInnserAddressBook] = useState(
    addressBook.collection,
  );
  const navigate = useNavigate();

  const handleAfterDeletingAddress = (id: string) => {
    const _addressBook = innserAddressBook.filter(
      (item: any) => item.object_id !== id,
    );

    setInnserAddressBook(_addressBook);
  };

  const handleNavigation = (url: string) => {
    navigate(url, { replace: true });
    window.location.reload();
  };
  const getButtonClassName = ({path}: { path: any }) => {
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;

    const isActive = currentPath === '/address-book' &&
        ((!currentSearch && path === '/address-book') ||
            (currentSearch === '?category=store_to_door' && path === '/address-book?category=store_to_door'));

    return `${
        isActive
            ? styles.Active
            : ''
    }`;
  };

  return innserAddressBook.length ? (
    <>
      <div className={styles.NavigationAddressBook}>
        <button
            type="button"
            onClick={() => handleNavigation('/address-book')}
            className={getButtonClassName({path: '/address-book'})}
        >
          Shipper Global Addresses
        </button>
        <button
            type="button"
            onClick={() => handleNavigation('/address-book?category=store_to_door')}
            className={getButtonClassName({path: '/address-book?category=store_to_door'})}
        >
          Shopping Addresses
        </button>
      </div>

      <ul className={styles.ListAddressBook}>
        {innserAddressBook.map((item: any) => (
            <CardAddressBook
                item={item}
                key={item.object_id}
                afterDeletingAddress={handleAfterDeletingAddress}
            />
        ))}
      </ul>

      {addressBook.meta.last_page > 1 && (
          <div className={styles.ListAddressBookPagination}>
            <BasePagination
                count={addressBook.meta.last_page}
            currentPage={addressBook.meta.current_page}
            onChange={onChangePage}
          />
        </div>
      )}
    </>
  ) : (
    <EmptyListAddressBook />
  );
};
