import { useState } from "react";
import { useNavigate } from "react-router";
import { BaseButton } from "../../components/BaseButton";
import { ModalDeleteCardAddressBook } from "./ModalDeleteCardAddressBook";
import { getCountryName } from "../../services/getCountryName";
import commonStyles from "../../css/common.scss";
import styles from "./CardAddressBook.scss";

interface CardAddressBookProps {
  item: any;
  afterDeletingAddress(id: string): void;
}

export const CardAddressBook = ({
  item,
  afterDeletingAddress,
}: CardAddressBookProps) => {
  const navigate = useNavigate();
  const [remoteAddressID, setRemoteAddressID] = useState("");
  const fullName = `${item.first_name} ${item.last_name}`;

  let companyStateCity = getCountryName(item.country_code);
  item.state && (companyStateCity += `, ${item.state}`);
  companyStateCity += `, ${item.city_name}`;

  return (
    <li
      className={`${commonStyles.commonCardThinBottom} ${styles.CardAddressBook}`}
    >
      <div className={styles.CardAddressBookContent}>
        <div className={styles.CardAddressBookContentLeft}>
          <h6 className={styles.CardAddressBookContentLeftTitle}>
            {item.title}
          </h6>

          <div className={styles.CardAddressBookContentLeftContact}>
            <div>{fullName}</div>
            <div>{companyStateCity}</div>
            <div>{item.street_1}</div>
            {item.street_2 && <div>{item.street_2}</div>}
            <div>{item.postal_code}</div>
          </div>
        </div>

        <div className={styles.CardAddressBookContentRight}>
          {item.is_main_address && <div>Main address</div>}
          {item.is_default_billing_address && <div>Billing address</div>}
        </div>
      </div>

      <div className={styles.CardAddressBookFooter}>
        <a
          className={styles.CardAddressBookFooterBtn}
          onClick={() => navigate(`/address-book/edit/${item.object_id}`)}
        >
          Edit
        </a>

        <BaseButton
          className={styles.CardAddressBookFooterBtn}
          clear
          onClick={() => setRemoteAddressID(item.object_id)}
        >
          Delete
        </BaseButton>
      </div>

      <ModalDeleteCardAddressBook
        addressID={remoteAddressID}
        afterDeletingAddress={afterDeletingAddress}
        open={!!remoteAddressID}
        onClose={() => setRemoteAddressID("")}
      />
    </li>
  );
};
