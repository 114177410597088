import { useState } from "react";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { HF } from "../../components";
import { onlyLetters } from "../../components/BaseInput/BaseInput";
import { ToggleBtn } from "../../components/ToggleBtn";
import { CountrySelectV2 } from "../../components/CountrySelectV2";
import { BaseSearchInput } from "../../components/BaseSearchInput/BaseSearchInput";
import { BaseCheckbox } from "../../components/BaseCheckbox";
import { ControlFormAppAddressBookEditor } from "./ControlFormAppAddressBookEditor";
import { validationSchemaAddressbook } from "./formValidationSchema.AppAddressBookEditor";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";
import commonStyles from "../../css/common.scss";
import styles from "./FormAppAddressBookEditor.scss";
import { createAddressToBook } from "../../api/commonRequests";
import { updateAddressInBook } from "./_apiRequests.AppAddressBookEditor";
import { updateUser } from "../profile/data/actions";
import { CompanyEinTooltip } from "../addressBookEditor/CompanyEinTooltip";
import { PassportTooltip } from "../addressBookEditor/PassportTooltip";
import { geocodeByAddressHandler } from "../../services/geocodeByAddressHandler";
import { useLocation } from "react-router-dom";

interface FormAppAddressBookEditorProps {
  address?: any;
}

export const FormAppAddressBookEditor = ({
  address,
}: FormAppAddressBookEditorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: address || {},
    resolver: validationSchemaAddressbook,
  });
  const location = useLocation();
  const category = (new URLSearchParams(location.search)).get("category");

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isCompany, setIsCompany] = useState(
    !!methods.getValues("company_name")
  );
  const [addressSearchInput, setAddressSearchInput] = useState<any>(
    address
      ? {
          ...address,
        }
      : {}
  );
  const [originalForm, setOriginalForm] = useState<any>(
    address
      ? {
          ...address,
        }
      : {}
  );

  const { isMobileDevice } = useIsResponsive();

  const onSubmit = async () => {
    setLoadingSubmit(true);

    const outgoingData = methods.getValues();

    const coordinates: any = await geocodeByAddressHandler(outgoingData);
    // console.log("=coordinates=", coordinates);

    outgoingData.lat = coordinates.lat;
    outgoingData.lng = coordinates.lng;

    // console.log("=outgoingData=", outgoingData);

    try {
      outgoingData["set_as_main_address"] = outgoingData["is_main_address"];
      outgoingData["set_as_default_billing_address"] =
      outgoingData["is_default_billing_address"];

      if (!outgoingData['object_id'] && category && category === "store_to_door") {
        outgoingData['category'] = category;
      }

      const res = address
        ? await updateAddressInBook(outgoingData)
        : await createAddressToBook(outgoingData);
      // console.log("=res=", res);

      if (res) {
        setOriginalForm({ ...res });

        const updatedProfileData: any = {};

        res.is_main_address &&
          (updatedProfileData.main_address_book_address = res);
        res.is_default_billing_address &&
          (updatedProfileData.default_billing_address_book_address = res);

        Object.keys(updatedProfileData).length &&
          dispatch(updateUser(updatedProfileData));

        let queryCategory = null;

        if (outgoingData['category'] && outgoingData['category'] === 'store_to_door') {
          queryCategory = 'store_to_door';
        }

        navigate(`/address-book${queryCategory ? `?category=${queryCategory}` : ""}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleOnKeyPress = (key: any, value: any): boolean => {
    const spaceCount = `${value}${key}`.match(/\s/g)?.length || 0;
    const hyphenCount = `${value}${key}`.match(/-/g)?.length || 0;

    if (spaceCount > 1) return false;
    if (hyphenCount > 1) return false;
    if (spaceCount && hyphenCount) return false;

    return /[A-Z]-?(\s)?[A-Z]?$/gi.test(`${value}${key}`);
  };

  const handleBlur = (fieldName: string, value: string): void => {
    if (value[value.length - 1] === "-") {
      methods.setValue(fieldName, value.slice(0, value.length - 1).trim());
    } else {
      methods.setValue(fieldName, value.trim());
    }
  };

  const handleSelectedAddress = (selectedAddress: any) => {
    setAddressSearchInput(selectedAddress);
    methods.setValue("city_name", selectedAddress["city_name"]);
    methods.setValue("state", selectedAddress["state"]);
    methods.setValue("street_1", selectedAddress["street_1"]);
    methods.setValue("postal_code", selectedAddress["postal_code"] ?? "");
  };

  const handleTogglePrivateCompany = (value: boolean) => {
    methods.setValue("company_name", "");
    methods.setValue("company_ein", "");
    methods.setValue("passport", "");
    setIsCompany(value);
  };

  return (
    <div className={`${commonStyles.commonCardThinBottom}`}>
      <FormProvider {...methods}>
        <div className={styles.FormAppAddressBookEditorTop}>
          {/* <p className={styles.FormAppAddressBookEditorTopReminderText}>
            * required fields
          </p> */}

          <ToggleBtn
            selected={isCompany}
            options={[
              {
                label: "Private",
                value: false,
              },
              {
                label: "Company",
                value: true,
              },
            ]}
            onChange={(value: boolean) => handleTogglePrivateCompany(value)}
          />
        </div>

        <form
          className={styles.FormAppAddressBookEditorForm}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            name="title"
            label="Address name"
          />

          <p className={styles.FormAppAddressBookEditorFormSectionTitle}>
            Contact details:
          </p>

          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            name="first_name"
            label="First Name"
            onKeyPress={handleOnKeyPress}
            onBlur={(e: any): void => handleBlur("first_name", e.target.value)}
            normalize={onlyLetters}
            onlyLetters
          />

          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            name="last_name"
            label="Last Name"
            onKeyPress={handleOnKeyPress}
            onBlur={(e: any): void => handleBlur("last_name", e.target.value)}
            normalize={onlyLetters}
            onlyLetters
          />

          <HF.PhoneInput
            className={styles.FormAppAddressBookEditorFormField}
            name="phone"
          />

          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            name="email"
            label="E-mail"
          />

          {isCompany ? (
            <div className={styles.FormAppAddressBookEditorFormCompany}>
              <HF.Input
                className={styles.FormAppAddressBookEditorFormField}
                name="company_name"
                label="Company name"
              />

              <div
                style={{ display: "flex", alignItems: "center" }}
                className={styles.FormAppAddressBookEditorFormField}
              >
                <HF.Input name="company_ein" label="Tax ID / VAT number" />

                <CompanyEinTooltip />
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={styles.FormAppAddressBookEditorFormField}
            >
              <HF.Input name="passport" label="Personal ID" />
              <PassportTooltip />
            </div>
          )}

          <p className={styles.FormAppAddressBookEditorFormSectionTitle}>
            Pick up adress:
          </p>

          <CountrySelectV2
            name="country_code"
            className={styles.FormAppAddressBookEditorFormField}
            countryCode={methods.watch("country_code")}
            disableClearable
            required
            onChange={(country: any) => {
              if (country.value !== methods.getValues("country_code")) {
                handleSelectedAddress({});
              }
              methods.setValue("country_code", country.value);
            }}
          />

          <BaseSearchInput
            className={styles.FormAppAddressBookEditorFormField}
            address={addressSearchInput}
            label="City"
            dropdownUp={isMobileDevice}
            searchOptions={{
              types: ["(cities)"],
              componentRestrictions: {
                country: methods.getValues("country_code"),
              },
            }}
            disabled={!methods.watch("country_code")}
            onSelect={handleSelectedAddress}
          />

          <BaseSearchInput
            className={styles.FormAppAddressBookEditorFormField}
            address={addressSearchInput}
            prefixToSearchString={methods.getValues("city_name")}
            label="Street"
            dropdownUp={isMobileDevice}
            searchOptions={{
              types: ["address"],
              componentRestrictions: {
                country: methods.getValues("country_code"),
              },
            }}
            disabled={!methods.watch("city_name")}
            minlength="3"
            maxlength="35"
            pattern=".{3,35}"
            title="3 to 35 characters"
            required
            onBlur={(value: any) => {
              methods.setValue("street_1", value);
            }}
            onSelect={handleSelectedAddress}
          />

          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            disabled={!methods.watch("street_1")}
            name="street_2"
            label="Entrance, floor, apartment, etc."
            maxlength="35"
          />

          <HF.Input
            className={styles.FormAppAddressBookEditorFormField}
            value={methods.watch("postal_code") || ""}
            disabled={!methods.watch("street_1")}
            name="postal_code"
            label="Zip"
          />

          {
            ((!category && address?.category !== 'store_to_door') || (category && category !== "store_to_door")  ) ? (
                <>
                <BaseCheckbox
                    className={styles.FormAppAddressBookEditorFormField}
                    checked={!!methods.watch("is_main_address")}
                    label="Save as main address"
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => {
                      methods.setValue("is_main_address", checked);
                    }}
                />

                <BaseCheckbox
                    className={styles.FormAppAddressBookEditorFormField}
                    // value={!!methods.watch('is_default_billing_address')}
                    checked={!!methods.watch("is_default_billing_address")}
                    label="Set billing address as default"
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => {
                      methods.setValue("is_default_billing_address", checked);
                    }}
                />
                </>
            ) : ''
          }

          <ControlFormAppAddressBookEditor
            className={styles.FormAppAddressBookEditorFormControl}
            methods={methods}
            originalForm={originalForm}
            isCompany={isCompany}
            loadingSubmit={loadingSubmit}
          />
        </form>
      </FormProvider>
    </div>
  );
};
