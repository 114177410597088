import { useNavigate } from "react-router";
import { BaseIcon, Name } from "../../components/BaseIcon";
import styles from "./AddNewAddressBtn.AppAddressBook.scss";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";

export const AddNewAddressBtn = (category: string | null | {} = null) => {
  const navigate = useNavigate();
  const { isMobileDevice } = useIsResponsive();

  return (
    <a
      className={styles.addNewAddressBtn}
      onClick={() => navigate(`/address-book/create${category ? `?category=${category}` : ""}` as string)}
    >
      <BaseIcon name={Name.Plus} size={14} />

      <span className={styles.addNewAddressBtnLabel}>
        {isMobileDevice ? "Add new" : "Add new address"}
      </span>
    </a>
  );
};
